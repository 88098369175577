class PagesBuildType extends HTMLElement {
  async changeListener(event: Event) {
    const target = event.target as HTMLInputElement
    if (target.name !== 'build_type') return

    this.setAttribute('current', target.value)
    this.getElementsByTagName('pages-jekyll-config')[0]!.setAttribute('hidden', 'true')
    this.updateBuildType(target)
  }

  async updateBuildType(element: HTMLInputElement) {
    const form = element.form!
    form.submit()
    for (const button of this.querySelectorAll<HTMLInputElement>("[name='build_type']")) {
      button.disabled = true
    }
  }

  connectedCallback() {
    this.addEventListener('change', this.changeListener)
  }

  disconnectedCallback() {
    this.removeEventListener('change', this.changeListener)
  }
}

if (!window.customElements.get('pages-build-type')) {
  window.customElements.define('pages-build-type', PagesBuildType)
}
