import {
  enableSaveSecretButton,
  updateSelectedRepositoryCount as updateMainFormSelectedRepositoryCount,
} from './org-secrets'
import {updateVariablesSelectedRepositoryCount as updateVariablesSelectedRepositoryCount} from './org-variables'
// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
import DetailsDialogElement from '@github/details-dialog-element'
import {fetchSafeDocumentFragment} from '../fetch'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

const dataStore = new WeakMap<Element, number>()
const stateChangedCheckboxes = new WeakMap<Element, Set<string>>()

// The maximum number of times we should load another page of pinnable items automatically, just
// when the user wants to filter the list of pinnable items
const repoPageLimit = 100

async function loadMoreRepositoryItems(loadMoreButton: HTMLButtonElement) {
  const repositorySelectionComponent = loadMoreButton.closest<HTMLElement>('.js-repository-selection-component')!
  const timesLoaded = dataStore.get(repositorySelectionComponent) || 0
  if (timesLoaded > repoPageLimit) {
    return
  }

  loadMoreButton.textContent = loadMoreButton.getAttribute('data-disable-with')!
  loadMoreButton.disabled = true

  dataStore.set(repositorySelectionComponent, timesLoaded + 1)

  const url = loadMoreButton.getAttribute('data-url')!
  fetchItemsAsync(repositorySelectionComponent, url)

  // This event needs to fire after the data-action is bound
  await Promise.resolve()
  repositorySelectionComponent.dispatchEvent(new CustomEvent('repository-items-loaded'))
}

async function fetchItemsAsync(repositorySelectionComponent: HTMLElement, url: string) {
  const html = await fetchSafeDocumentFragment(document, url)

  repositorySelectionComponent.querySelector<HTMLElement>('.js-more-repository-items-replace-target')!.replaceWith(html)

  // When a page of repository items is loaded into the DOM, update the filtering in case the user
  // has typed a query into the text input
  filterRepositoryItems(repositorySelectionComponent)
}

on('click', '.js-more-repository-items-button', function (event: Event) {
  const button = event.currentTarget as HTMLButtonElement
  loadMoreRepositoryItems(button)
})

observe('.js-more-repository-items-button', {
  constructor: HTMLButtonElement,
  add: loadMoreRepositoryItems,
})

function filterRepositoryItems(repositorySelectionComponent: HTMLElement) {
  fire(repositorySelectionComponent.querySelector<HTMLElement>('.js-repository-items-filter-input')!, 'change')
}

function saveChangedCheckbox(event: Event) {
  const checkbox = event.target as HTMLInputElement

  if (!(checkbox?.type === 'checkbox')) return

  const selectionComponent = checkbox.closest<HTMLElement>('.js-repository-selection-component')!
  const changedSet = stateChangedCheckboxes.get(selectionComponent) || new Set<string>()

  // If a checkbox is toggled twice, it is back to its original state, so we should forget it
  if (changedSet.has(checkbox.value)) changedSet.delete(checkbox.value)
  else changedSet.add(checkbox.value)

  stateChangedCheckboxes.set(selectionComponent, changedSet)

  const container = checkbox.closest<HTMLElement>('.js-repository-items-dialog')!
  if (!container) return

  setUpdateSelectionButtonState(container, changedSet.size > 0)
}

function setUpdateSelectionButtonState(container: HTMLElement, enable: boolean) {
  const updateSelectionButton = container.querySelector<HTMLButtonElement>('.js-btn-select-repositories')!
  updateSelectionButton.disabled = !enable
}

function undoChangedCheckboxes(event: Event) {
  const container = (event.currentTarget as Element).closest<HTMLElement>('.js-repository-items-dialog')
  if (!container) return

  const repositorySelectionComponent = container.querySelector<HTMLElement>('.js-repository-selection-component')
  if (!repositorySelectionComponent || !repositorySelectionComponent.hasAttribute('data-discard-changes-on-close'))
    return

  const changedSet = stateChangedCheckboxes.get(repositorySelectionComponent)
  if (!changedSet) return

  for (const value of changedSet.keys()) {
    const checkbox = repositorySelectionComponent.querySelector<HTMLInputElement>(`[value='${value}']`)!
    checkbox.checked = !checkbox.checked
  }

  // Update the desired state on exit-without-save by clearing the set of checkboxes that will be toggled
  stateChangedCheckboxes.set(repositorySelectionComponent, new Set())

  setUpdateSelectionButtonState(container, false)
}

function updateSelectedRepositoryCount(event: CustomEvent) {
  const repositorySelectionComponent = event.currentTarget as Element
  const container = repositorySelectionComponent.closest('.js-repository-items-dialog')
  if (!container) return

  const count = Array.from(
    repositorySelectionComponent.querySelectorAll<HTMLInputElement>('.js-repository-item-checkbox'),
  ).filter(el => el.checked).length

  const itemsCountContainer = container.querySelector<HTMLElement>('.js-selected-repositories-count-container')!
  itemsCountContainer.querySelector<HTMLElement>('.js-selected-repositories-count')!.textContent = count.toString()
  itemsCountContainer.querySelector<HTMLElement>('.js-multiple-repositories-text')!.hidden = count === 1
  itemsCountContainer.querySelector<HTMLElement>('.js-single-repository-text')!.hidden = count !== 1
}

on('change', '.js-repository-selection-component', updateSelectedRepositoryCount)
on('reset', '.js-repository-selection-component', updateSelectedRepositoryCount)
on('change', '.js-repository-selection-component', saveChangedCheckbox)
on('click', '.js-btn-select-repositories', function (event) {
  const container = (event.target as Element).closest<HTMLElement>('.js-repository-items-dialog')!
  const repositorySelectionComponent = container.querySelector<HTMLElement>('.js-repository-selection-component')!

  stateChangedCheckboxes.set(repositorySelectionComponent, new Set())

  // Close the details dialog
  const detailsDialog = repositorySelectionComponent.closest<DetailsDialogElement>('details-dialog')!
  detailsDialog.toggle(false)

  const orgSecretsSelection = document.querySelector<HTMLElement>('.js-org-secrets-selection')!
  if (orgSecretsSelection) {
    updateMainFormSelectedRepositoryCount()
    enableSaveSecretButton(event)
  }

  const orgVariablesSelection = document.querySelector<HTMLElement>('.js-org-variables-selection')!
  if (orgVariablesSelection) {
    updateVariablesSelectedRepositoryCount()
  }
})
on('details-dialog-close', '.js-repository-items-dialog', undoChangedCheckboxes)
