// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

interface JsonRemediationResult {
  error: boolean
  remediation: 'UNVERIFIABLE' | 'ACTIVE' | 'REVOKED'
  event_at: string
}

interface FetchRemediationResponse {
  remediationResult: JsonRemediationResult | null
  row: HTMLElement
}

const fetchRemediation = async (row: HTMLElement): Promise<FetchRemediationResponse> => {
  const fetchPath = row.getAttribute('data-remediation-path')
  if (fetchPath == null) {
    return {remediationResult: null, row}
  }
  try {
    const response = await fetch(`${fetchPath}.json`)
    const json = (await response.json()) as JsonRemediationResult
    return {remediationResult: json, row}
  } catch (error) {
    return {remediationResult: null, row}
  }
}

let requesting = false
observe('.js-navigation-container', () => {
  if (requesting) {
    return
  }
  requesting = true
  const rows = Array.from(document.querySelectorAll<HTMLElement>('.js-alert-row')).filter(item => {
    const showRemediation = item.getAttribute('data-show-remediation')
    return showRemediation === 'true'
  })
  const promises = rows.map(r => fetchRemediation(r))
  // eslint-disable-next-line github/no-then
  Promise.all(promises).then((respones: FetchRemediationResponse[]) => {
    const validRespones = respones.filter(r => r.remediationResult !== null)
    for (const res of validRespones) {
      const row = res.row
      if (res.remediationResult?.remediation === 'ACTIVE') {
        const label = row.querySelector<HTMLElement>('.js-secret-scanning-row-validity-active')
        if (label) {
          label.hidden = false
        }
      }
    }
    requesting = false
  })
})
