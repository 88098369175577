import {controller} from '@github/catalyst'
import {BaseFilterElement} from '../filter-input'
import {requestSubmit} from '@github-ui/form-utils'
import {fetchSafeDocumentFragment} from '../fetch'

type CustomEventDetail = {
  loadingType: 'filter' | 'loadMore'
}

// the following regex should be in sync with the regex in app/helpers/actions_repository_bulk_selector_helper.rb
const TOKENIZER_REGEX = new RegExp(/[^._\-/]+/, 'g')
const QUERY_REGEX = new RegExp(/(IN|OUT)\(([^()]+)\)/, 'g')
// note: we do not allow org: filter in the front end, but only in backend
const EXPRESSION_REGEX = new RegExp(/((language|in|is){1}:("[^"]+"|\S+))|(\S+)/, 'g')
const NUMERIC_REGEX = new RegExp(/\d+/, 'g')
const CAMEL_CASE_REGEX = new RegExp(/(^[^A-Z]|[A-Z]+)[a-z0-9._\-/]*/, 'g')

const MAX_NGRAM = 20

@controller
export class RepositoryListFilterElement extends BaseFilterElement {
  selectedSourceRepoId = 0
  submittedFilterInput = ''

  // (override) called on keyboard event
  override inputKey(event: KeyboardEvent) {
    /* eslint eslint-comments/no-use: off */
    /* eslint-disable no-restricted-syntax */
    if (event.key === 'Escape') {
      // only prevent propagation if the autocomplete dropdown is currently open
      if (!this.autocompleteDropdown.hidden) {
        event.stopPropagation()
      }
      this.handleSearchBlur()
    }
    /* eslint-enable no-restricted-syntax */
  }

  // (override) called when the clear button is clicked
  override clear() {
    // reset the input
    this.searchInput.value = this.getDefaultSearch()

    // re-request the original list
    requestSubmit(this.searchForm)

    // hide the clear button if the input is empty
    if (this.searchInput.value.trim().length === 0 && this.clearButton) {
      this.clearButton.hidden = true
    }
  }

  // (override) -- trim the suggestions to, at max, 7 items to handle the previously long list of languages
  // Render autocomplete suggestions to match qualifier values from the currently typed text.
  //
  // qualifier - Qualifier to find value suggestions for.
  // filterString - Filter input to filter suggestions by.
  override async renderValueSuggestions(qualifier: string, filterString: string) {
    let matchingSuggestions = await this.fetchMatchingSuggestions(qualifier, filterString)

    // truncate the list to top 7 suggestions
    if (matchingSuggestions.length > 7) {
      matchingSuggestions = matchingSuggestions.slice(0, 7)
    }

    this.renderSuggestionDropdown(Promise.resolve(matchingSuggestions))
  }

  // (override)
  // Parses the current search query to decide what autocompletes to show
  override updateFilterSuggestionResults(): void {
    super.updateFilterSuggestionResults()

    // we want to keep the clear button visible as long as the for hasn't been submitted with an empty search
    const searchString = this.searchInput.value
    if (this.clearButton) {
      if (searchString.trim().length === 0 && this.submittedFilterInput.length !== 0 && this.clearButton.hidden) {
        // turn on the clear button when the input is empty as long as the clear button was already visible
        // this prevents a bug where the user focuces on the empty input and unfocuses which would cause the
        // clear button to appear even though no query has happened.
        this.clearButton.hidden = false
      } else if (searchString.trim().length > 0) {
        // turn on the clear button whenever the input has text
        this.clearButton.hidden = false
      }
    }
  }

  handleSubmitEvent(e: Event) {
    e.preventDefault()

    if (this.invalidSearchTerms()) {
      // don't submit invalid queries to the controller
      return
    }

    this.submittedFilterInput = this.formatSearchInput(this.searchInput.value.trim())

    this.fetchRepositories()
    // hide the dropdown after submitting to the controller
    this.hideFilterSuggestions()
  }

  fetchRepositories(_page = 1) {
    const url = this.searchForm.action
    const formattedSearchInput = this.submittedFilterInput
    const query = encodeURIComponent(formattedSearchInput)
    const constructedUrl = `${url}&page=${_page}&q=${query}&source_repo_id=${this.selectedSourceRepoId}`
    this.fetchRepositoriesContent(_page, constructedUrl)
  }

  emitLoadingEvent(type: 'loading' | 'loaded' | 'error', detail: CustomEventDetail): void {
    this.dispatchEvent(new CustomEvent(type, {detail}))
  }

  formatSearchInput(searchInput: string) {
    searchInput = searchInput.trim()
    if (!searchInput) {
      return ''
    }

    const queryItems = this.getExpressionArray(searchInput)
    const newQuery = queryItems.map(queryItem => {
      if (queryItem.includes(':')) {
        return queryItem
      }
      return `${queryItem} in:name`
    })

    return newQuery.join(' ')
  }

  async fetchRepositoriesContent(_page: number, url: string) {
    const isFetchingFromFilter = _page === 1

    const customEventDetail: CustomEventDetail = {
      loadingType: isFetchingFromFilter ? 'filter' : 'loadMore',
    }

    this.emitLoadingEvent('loading', customEventDetail)

    const htmlResponse = await fetchSafeDocumentFragment(document, url)

    if (isFetchingFromFilter) {
      document.getElementById('repository-items-replacement-target')?.replaceChildren(htmlResponse)
    } else {
      // Load More functionality
      document.getElementById('load-more-list-item')?.replaceWith(htmlResponse)
    }

    // apply selection based on the query
    this.applyFilters()

    // propagate the filter event to parent containers that may be performing actions on this event
    this.dispatchEvent(new CustomEvent('filtered'))

    this.emitLoadingEvent('loaded', customEventDetail)
  }

  // This applies the selection on client side based on expressions
  // Similar to code app/helpers/actions_repository_bulk_selector_helper.rb
  applyFilters() {
    const repoBulkSelector = this.closest<HTMLElement>('repository-bulk-selector')!
    // Get the internal query value
    const repoQueryValue = repoBulkSelector.getElementsByTagName('input')[0]!.value
    if (!repoQueryValue) return

    const repoItemsContainer = repoBulkSelector.getElementsByTagName('ul')[0]!

    // In case of load more, apply to the newly added items to avoid duplicate effort
    const repoItems = repoItemsContainer.getElementsByTagName('input')

    // reference: https://github.com/github/github/blob/master/app/helpers/actions_repository_bulk_selector_helper.rb#L128
    // Handle query: = 'IN(language:javascript) OUT(1,2)'
    const queries = this.getQueryArray(repoQueryValue)

    for (const repoItem of repoItems) {
      let checked = repoItem.checked

      for (let query of queries) {
        query = query.trim()
        if (!query) continue
        let filtered = false

        const phrase = this.getPhrase(query)

        if (!phrase) continue

        if (this.isFilterQuery(phrase)) {
          // Simple expression: IN(language:JavaScript)
          // Complex expression IN(language:JavaScript -is:private)
          const expressionArray = this.getExpressionArray(phrase)
          filtered = this.filterExpressions(expressionArray, repoItem)
        } else if (this.isAllExpression(phrase)) {
          filtered = true
        } else {
          // Handle targetIds IN(1,2,3)
          const targetIds = phrase.split(',')
          filtered = this.handleTargetIDs(targetIds, repoItem)
        }

        if (filtered && query.startsWith('IN')) {
          checked = true
        } else if (filtered && query.startsWith('OUT')) {
          checked = false
        }
      }
      repoItem.checked = checked
    }
  }

  // 'IN(language:javascript) OUT(1,2)' => ['IN(language:javascript)', 'OUT(1,2)']
  getQueryArray(repoQueryValue: string) {
    const matches = repoQueryValue.matchAll(QUERY_REGEX)
    return [...matches].map(m => m[0])
  }

  // 'IN(language:javascript)' => 'language:javascript'
  getPhrase(query: string) {
    const matches = query.matchAll(QUERY_REGEX)
    return [...matches].map(m => m[2])[0]
  }

  // 'language:"Emacs Lisp" test.42 in:name is:private' => ['language:"Emacs Lisp"', 'test.42', 'in:name', 'is:private']
  getExpressionArray(phrase: string) {
    return phrase.match(EXPRESSION_REGEX) || []
  }

  // 'language:javascript' => ['language', 'javascript']
  getExpressionValuePair(expression: string) {
    const pair = expression.trim().split(':')
    if (pair.length === 2 && this.isWrappedQuotation(pair[1]!)) {
      pair[1] = pair[1]!.slice(1, -1)
    }

    return pair
  }

  isWrappedQuotation(value: string) {
    return value.startsWith('"') && value.endsWith('"')
  }

  filterExpressions(expressions: string[], repoItem: HTMLInputElement) {
    let languageFilterExists = false
    let languageFiltered = false

    let visibilityFiltered = false
    let visibilityFilterExists = false

    let nameSearch = ''
    for (let expression of expressions) {
      expression = expression.trim()
      const expressionValuePair = this.getExpressionValuePair(expression)

      if (expressionValuePair.length === 1) {
        nameSearch = expressionValuePair[0]!.trim()
        continue
      }

      const qualifier = expressionValuePair[0]!
      const value = expressionValuePair[1]!
      if (qualifier === 'in' && value === 'name') {
        // early exit if name filter is not matched
        if (!this.filterNameExpression(nameSearch, repoItem)) return false
        continue
      }

      if (qualifier === 'language') {
        // this considers OR condition for `language:javascript language:shell`
        languageFiltered = languageFiltered || this.filterLanguageExpression(value, repoItem)
        languageFilterExists = true
        continue
      }

      if (qualifier === 'is') {
        // this considers OR condition for `is:private is:internal`
        visibilityFiltered = visibilityFiltered || this.filterVisibilityExpression(value, repoItem)
        visibilityFilterExists = true
        continue
      }
    }

    if (!languageFilterExists) {
      languageFiltered = true
    }

    if (!visibilityFilterExists) {
      visibilityFiltered = true
    }

    return languageFiltered && visibilityFiltered
  }

  filterLanguageExpression(value: string, repoItem: HTMLInputElement) {
    return repoItem.getAttribute('data-language')!.toLowerCase() === value.toLowerCase()
  }

  filterVisibilityExpression(value: string, repoItem: HTMLInputElement) {
    return repoItem.getAttribute('data-visibility')!.toLowerCase() === value.toLowerCase()
  }

  // the method 'filterNameExpression' should follow the implementation of 'filter_name_expression?' in app/helpers/actions_repository_bulk_selector_helper.rb
  filterNameExpression(searchValue: string, repoItem: HTMLInputElement) {
    const repoName = repoItem.getAttribute('data-name')?.trim()
    if (!repoName) {
      return false
    }
    if (!searchValue) {
      return true
    }

    const tokenizedSearchValues = searchValue.toLowerCase().match(TOKENIZER_REGEX) || []
    // if there is any token longer than max ngram 20, we cannot use ngram match
    const allowNgram = !tokenizedSearchValues.some(value => value.length > MAX_NGRAM)
    for (const tokenizedSearchValue of tokenizedSearchValues) {
      if (this.filterTokenizedNameExpression(tokenizedSearchValue, repoName, allowNgram) === false) {
        return false
      }
    }

    return true
  }

  exactOrNgramMatch(word: string, searchValue: string, ngramFlag: boolean) {
    const downcasedWord = word.toLowerCase()
    // exact word match
    if (downcasedWord === searchValue) {
      return true
    }
    if (ngramFlag && downcasedWord.slice(0, MAX_NGRAM).startsWith(searchValue)) {
      return true
    }

    return false
  }

  filterTokenizedNameExpression(tokenizedSearchValue: string, repoName: string, ngramFlag: boolean) {
    if (repoName.toLowerCase() === tokenizedSearchValue) {
      return true
    }

    // split camelcase: "PowerShot" => "Power" "Shot"
    const camelCaseWords = repoName.match(CAMEL_CASE_REGEX) || []
    for (const word of camelCaseWords) {
      if (this.exactOrNgramMatch(word, tokenizedSearchValue, ngramFlag)) {
        return true
      }
    }

    // number subwords to be generated: "500-42rg" => "500" "42"
    const numericWords = repoName.match(NUMERIC_REGEX) || []
    for (const word of numericWords) {
      if (this.exactOrNgramMatch(word, tokenizedSearchValue, ngramFlag)) {
        return true
      }
    }

    // split on numerics: "j2se" =>  "j" "se" ("2" will be covered in number subword)
    const nonNumericWords = repoName.split(NUMERIC_REGEX)
    for (const word of nonNumericWords) {
      if (this.exactOrNgramMatch(word, tokenizedSearchValue, ngramFlag)) {
        return true
      }
    }

    // split at non-alphanumerics(delimiters): "500-42rg" => "500" "42rg"
    const words = repoName.match(TOKENIZER_REGEX) || []
    for (const word of words) {
      if (this.exactOrNgramMatch(word, tokenizedSearchValue, ngramFlag)) {
        return true
      }
    }

    // tokenizedSearchValue does not match any of the words
    return false
  }

  handleTargetIDs(targetIds: string[], repoItem: HTMLInputElement) {
    return targetIds.includes(repoItem.getAttribute('data-repo-id')!)
  }

  isFilterQuery(expression: string) {
    return expression.includes(':')
  }

  isAllExpression(expression: string) {
    return expression === 'ALL'
  }

  setSelectedSourceRepoId(selectedSourceRepoId = 0) {
    this.selectedSourceRepoId = selectedSourceRepoId
  }
}
