import {controller, target, targets} from '@github/catalyst'
import IncludeFragmentElement from '@github/include-fragment-element'

@controller
export class SelectedItemListElement extends HTMLElement {
  @target selectedCount: HTMLElement
  @target pluralItemsDescription: HTMLElement
  @target singularItemDescription: HTMLElement
  @target description: HTMLElement
  @targets items: HTMLInputElement[]
  @target includeFragment: IncludeFragmentElement
  @target saveButton: HTMLButtonElement

  connectedCallback() {
    this.includeFragment?.addEventListener('include-fragment-replaced', () => {
      this.updateCount()
      if (this.saveButton) {
        this.saveButton.disabled = false
      }
    })
  }

  get count(): number {
    return this.items.filter(item => item.checked).length
  }

  updateCount() {
    const count = this.count
    this.selectedCount.textContent = String(count)

    this.description.hidden = count <= 0
    this.pluralItemsDescription.hidden = count === 1
    this.singularItemDescription.hidden = count !== 1
  }

  hideDescription() {
    this.description.hidden = true
  }

  showDescription() {
    const count = this.count
    this.description.hidden = count <= 0
  }
}
