import type AutocompleteElement from '@github/auto-complete-element'
import {TemplateInstance} from '@github/template-parts'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onKey} from '../onfocus'
import {parseHTML} from '../parse-html'

async function submitAndUpdate(event: CustomEvent) {
  const form = event.currentTarget as HTMLFormElement
  event.preventDefault()
  submitAndUpdateForm(form)
}

async function submitAndUpdateForm(form: HTMLFormElement) {
  const response = await fetch(form.action, {
    method: form.method,
    body: new FormData(form),
    headers: {Accept: 'text/fragment+html'},
  })

  // Show an error message (if request fails)
  if (response.status >= 400) {
    // eslint-disable-next-line i18n-text/no-en
    const message = 'An unknown error occurred.'
    const template = document.querySelector<HTMLTemplateElement>('template.js-flash-template')!
    template.after(new TemplateInstance(template, {className: 'flash-error', message}))
  } else {
    const target = <HTMLElement>form.closest('[data-replace-remote-form-target]')
    const partial = parseHTML(document, await response.text())
    target.replaceWith(partial)
  }
}

on('submit', '.js-dependabot-selected-repos-remove-form', submitAndUpdate)

// Add a selected repo to the list and clear the search box
on('auto-complete-change', '.js-dependabot-selected-repos-add-auto-complete', function (event) {
  const autoComplete = event.target as AutocompleteElement
  if (!autoComplete.value) {
    return
  }

  const input = autoComplete.querySelector<HTMLInputElement>('.js-dependabot-selected-repos-add-input')
  input!.value = autoComplete.value
  autoComplete.value = ''

  const addForm = <HTMLFormElement>autoComplete.closest('form')
  submitAndUpdateForm(addForm)
})

// Don't let users accidentally submit the form when you hit enter
onKey('keydown', '.js-dependabot-selected-repos-add-search', function (event: KeyboardEvent) {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable no-restricted-syntax */
  if (event.key === 'Enter') {
    event.preventDefault()
  }
  /* eslint-enable no-restricted-syntax */
})
